import React from "react";
import { Flex, Box, Button, FlexProps, Heading, Link } from "rebass";
import { Label, Input, Select } from "@rebass/forms";
import { useCallback, useState } from "react";
import { useApiResponse } from "../hooks/useApiResponse";
import authStore, { useAuthStore } from "../stores/AuthStore";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { User, UserGender } from "../types/User";
import { differenceInCalendarYears, isValid, parseISO } from "date-fns";
import { TitleBarWrapper } from "./NavBarWrapper";
import { countries, TCountryCode } from "countries-list";

import "date-input-polyfill-react";
import { AuthenticatedAPI } from "../util/endpoints";
import { validateEmail, validateUsername } from "../util/misc";

const countryOrder = (Object.keys(countries) as Array<TCountryCode>).sort((a, b) => countries[a].native.localeCompare(countries[b].native));

const FlexForm: React.ComponentType<FlexProps> = (Flex as any).withComponent('form')

const genderIcons = {
  [UserGender.FEMALE]: "♀",
  [UserGender.MALE]: "♂",
  [UserGender.NONBINARY]: "⚥",
};

export function getGenderIcon(gender: UserGender) {
  return genderIcons[gender] || "";
}

export function getGenderName(gender: UserGender, intl: IntlShape) {
  switch (gender) {
    case UserGender.FEMALE:
      return intl.formatMessage({ id: "profile.gender.FEMALE", defaultMessage: "Female" });
    case UserGender.MALE:
      return intl.formatMessage({ id: "profile.gender.MALE", defaultMessage: "Male" });
    case UserGender.NONBINARY:
      return intl.formatMessage({ id: "profile.gender.NONBINARY", defaultMessage: "Non-binary" });
    default:
      return "";
  }
}

export function getAge(birthDay: string | null | undefined): number | undefined {
  const parsedDate = parseISO(birthDay || "");
  if (!isValid(parsedDate)) return undefined;
  return differenceInCalendarYears(new Date(), parsedDate);
}

const emptyObject = {}

export function ProfileFormInner({
  authApi,
  user,
  onUserChange,
  ...props
}: { authApi: AuthenticatedAPI; user: User; onUserChange: (user: User) => void } & FlexProps) {
  const [patch, setPatch] = useState<Partial<User>>(emptyObject);
  const [attempt, setAttempt] = useState<object | null>(null);

  const intl = useIntl();

  const onChangeUsername = useCallback(({ target: { value } }) => setPatch((p) => ({ ...p, username: value })), []);
  const onChangeEmail = useCallback(({ target: { value } }) => setPatch((p) => ({ ...p, email: value })), []);
  const onChangeFirstName = useCallback(({ target: { value } }) => setPatch((p) => ({ ...p, firstName: value })), []);
  const onChangeLastName = useCallback(({ target: { value } }) => setPatch((p) => ({ ...p, lastName: value })), []);
  const onChangeBirthDay = useCallback(({ target: { value } }) => setPatch((p) => ({ ...p, birthDay: value })), []);
  const onChangeGender = useCallback(({ target: { value } }) => setPatch((p) => ({ ...p, gender: value })), []);
  const onChangeCountry = useCallback(({ target: { value } }) => setPatch((p) => ({ ...p, country: value })), []);

  const requestState = useApiResponse(
    () =>
      attempt && (patch !== emptyObject)
        ? {
            invocation: authApi.patchUser(user.id, patch),
            onSuccess: (data) => {
              setPatch(emptyObject);
              onUserChange(data);
            },
          }
        : null,
    [attempt, authApi, onUserChange], // Intentionately not including patch to deps
  );

  const resolvedUser = user && { ...user, ...patch };
  const valid = !!(
    resolvedUser &&
    validateUsername(resolvedUser.username) &&
    (resolvedUser.email == null || validateEmail(resolvedUser.email)) &&
    resolvedUser.firstName &&
    resolvedUser.lastName &&
    resolvedUser.gender != null &&
    resolvedUser.country != null &&
    isValid(parseISO(resolvedUser.birthDay || ""))
  );

  const onSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      if (!valid) {
        return;
      }
      setAttempt(() => ({}));
    },
    [valid],
  );

  if (!user || !resolvedUser) {
    return null;
  }

  return (
    <FlexForm
      flexDirection="column"
      alignItems="stretch"
      alignSelf="center"
      maxWidth={500}
      width="100%"
      px={2}
      py={3}
      onSubmit={onSubmit}
      {...props}
    >
      <Label htmlFor="username">
        <FormattedMessage id="profile.username" defaultMessage="Username" />
      </Label>
      <Input
        mb={2}
        id="username"
        autoComplete="username"
        type="text"
        value={resolvedUser.username || ""}
        onChange={onChangeUsername}
      />

      <Label htmlFor="email">
        <FormattedMessage id="profile.email" defaultMessage="E-mail address" />
      </Label>
      <Input
        mb={2}
        id="email"
        autoComplete="email"
        type="email"
        value={resolvedUser.email || ""}
        onChange={onChangeEmail}
      />

      <Label htmlFor="firstName">
        <FormattedMessage id="profile.first-name" defaultMessage="First Name" />
      </Label>
      <Input
        mb={2}
        id="firstName"
        autoComplete="given-name"
        type="text"
        value={resolvedUser.firstName || ""}
        onChange={onChangeFirstName}
      />

      <Label htmlFor="lastName">
        <FormattedMessage id="profile.last-name" defaultMessage="Last Name" />
      </Label>
      <Input
        mb={2}
        id="lastName"
        autoComplete="family-name"
        type="text"
        value={resolvedUser.lastName || ""}
        onChange={onChangeLastName}
      />

      <Label htmlFor="country">
        <FormattedMessage id="profile.country" defaultMessage="Country" />
      </Label>
      <Select mb={2} id="country" value={resolvedUser.country || ""} onChange={onChangeCountry}>
        {!resolvedUser.country && <option value=""></option>}
        {countryOrder.map(countryCode => (
          <option key={countryCode} value={countryCode}>
            {countries[countryCode].native}
          </option>
        ))}
      </Select>

      <Label htmlFor="birthDay">
        <FormattedMessage id="profile.birth-day" defaultMessage="Birth Date" />
      </Label>
      <Input
        mb={2}
        id="birthDay"
        type="date"
        autoComplete="bday"
        value={resolvedUser.birthDay || ""}
        onChange={onChangeBirthDay}
      />

      <Label htmlFor="gender">
        <FormattedMessage id="profile.gender" defaultMessage="Gender" />
      </Label>
      <Select mb={2} id="gender" value={resolvedUser.gender || ""} onChange={onChangeGender}>
        {!resolvedUser.gender && <option value=""></option>}
        <option value={UserGender.FEMALE}>
          {getGenderIcon(UserGender.FEMALE)} {getGenderName(UserGender.FEMALE, intl)}
        </option>
        <option value={UserGender.MALE}>
          {getGenderIcon(UserGender.MALE)} {getGenderName(UserGender.MALE, intl)}
        </option>
        <option value={UserGender.NONBINARY}>
          {getGenderIcon(UserGender.NONBINARY)} {getGenderName(UserGender.NONBINARY, intl)}
        </option>
      </Select>

      <Button mb={2} variant="primary" type="submit" disabled={!valid || requestState.loading}>
        <FormattedMessage id="profile.save" defaultMessage="Save" />
      </Button>
      {requestState.error && (
        <Box p={2} sx={{ borderRadius: "default" }} bg="error" color="textLight">
          {requestState.error.toString()}
        </Box>
      )}
    </FlexForm>
  );
}

export default function ProfileForm({
  showSignOut = false,
  onChange: onChange_,
}: {
  showSignOut?: boolean;
  onChange?: (user: User) => void;
}) {
  const authData = useAuthStore();
  const user = authData.userRequest.data;

  const onChange = useCallback(
    (user) => {
      if (onChange_) onChange_(user);
      authStore.setAuthData(authStore.data.authToken, authStore.data.userId, user);
    },
    [onChange_],
  );

  if (!user) return null;

  return (
    <Flex flexDirection="column" alignItems="center">
      <Heading textAlign="center" color="primary" mt={3}>
        <FormattedMessage id="profile.header" defaultMessage="Tell us about yourself" />
      </Heading>
      <ProfileFormInner authApi={authData.authApi} user={user} onUserChange={onChange} />
      {showSignOut && (
        <Link href="#" onClick={authStore.signOut}>
          <FormattedMessage id="profile.sign-out" defaultMessage="Sign out" />
        </Link>
      )}
    </Flex>
  );
}

export function ProfilePage() {
  return <TitleBarWrapper title={<FormattedMessage id="page.title.profile" defaultMessage="User profile" />}>
    <ProfileForm />
  </TitleBarWrapper>;
}
