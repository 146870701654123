import { LevelName } from "./Score";
import { TCountryCode } from "countries-list";

export enum UserGender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NONBINARY = "NONBINARY",
}

export enum UserRole {
  PLAYER = "PLAYER",
  FULL_GAME = "FULL_GAME",
  SUPERVISOR = "SUPERVISOR",
  REPORTS = "REPORTS",
  ADMIN = "ADMIN",
  APP_REVIEWER = "APP_REVIEWER",
}

export type LevelOverride = {
  unlocked: boolean | null;
  recommended: boolean;
  quiz: boolean;
  challenge: boolean;
};
export type LevelOverrides = Partial<Record<LevelName, LevelOverride>>;

export type User = {
  id: string;
  roles?: UserRole[];
  friendCode?: string;
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  country?: TCountryCode;
  birthDay?: string;
  gender?: UserGender;
  levelOverrides?: LevelOverrides
  purchasedFullVersion?: boolean;
};

export type LoginResponse = {
  userId: string;
  authToken: string;
};
