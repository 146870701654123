import { Input, Label, Select } from "@rebass/forms";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Button, Flex } from "rebass";
import { SidebarTabProps } from "./SidebarUtils";
import "date-input-polyfill-react";
import { endOfDay, isValid, parseISO, startOfDay } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { apiProductionUrl } from "../../constants/servers";
import authStore from "../../stores/AuthStore";
import { LevelName } from "../../types/Score";
import config from "../../util/config";

function downloadReport(endpoint: string, data: any) {
  const authToken = authStore.data.authToken;
  if (!authToken) return;

  const url = apiProductionUrl + endpoint + "?auth_token=" + authToken;

  const form = document.createElement("form");
  form.action = url;
  form.method = "POST";

  if (data != null) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "data";
    input.value = JSON.stringify(data);
    form.appendChild(input);
  }

  document.body.append(form);
  form.submit();
  form.remove();
}

export function ReportsSidebarTab({ authApi, authUser, selectedUsers }: SidebarTabProps) {
  const [startDate, setStartDate] = useState("");

  const onStartDateChange = useCallback(evt => {
    const parsedDate = parseISO(evt.target.value);
    if (!evt.target.value || isValid(parsedDate)) {
      setStartDate(evt.target.value || "");
    }
  }, [])

  const onStartDateClear = useCallback(() => { setStartDate(""); }, []);

  const [endDate, setEndDate] = useState("");

  const onEndDateChange = useCallback(evt => {
    const parsedDate = parseISO(evt.target.value);
    if (!evt.target.value || isValid(parsedDate)) {
      setEndDate(evt.target.value || "");
    }
  }, [])

  const onEndDateClear = useCallback(() => { setEndDate(""); }, []);

  const [level, setLevel] = useState<LevelName | null>(null);
  const onChangeLevel = useCallback(evt => {
    const value = evt.target.value;
    setLevel((LevelName as any)[value] !== undefined ? value : null);
  }, []);

  const startTimestamp = useMemo<number | undefined>(() => {
    const parsedDate = parseISO(startDate);
    if (!isValid(parsedDate)) return undefined;
    return startOfDay(parsedDate).getTime();
  }, [startDate]);

  const endTimestamp = useMemo<number | undefined>(() => {
    const parsedDate = parseISO(endDate);
    if (!isValid(parsedDate)) return undefined;
    return endOfDay(parsedDate).getTime();
  }, [endDate]);

  const [format, setFormat] = useState<string>("text/csv");
  const onChangeFormat = useCallback(evt => {
    setFormat(evt.target.value);
  }, []);

  const onHighScoreClick = useCallback(() => {
    downloadReport("/reports/aggregated", {
      format,
      type: "high_score",
      userIds: selectedUsers,
      level: level ?? LevelName.TOTAL,
      startTimestamp,
      endTimestamp,
    });
  }, [format, selectedUsers, level, startTimestamp, endTimestamp]);

  const onLatestScoreClick = useCallback(() => {
    downloadReport("/reports/aggregated", {
      format,
      type: "latest_score",
      userIds: selectedUsers,
      level: level ?? LevelName.TOTAL,
      startTimestamp,
      endTimestamp,
    });
  }, [format, selectedUsers, level, startTimestamp, endTimestamp]);

  const onHistoryClick = useCallback(() => {
    downloadReport("/reports/history", {
      format,
      userIds: selectedUsers,
      level: level ?? undefined,
      startTimestamp,
      endTimestamp,
    });
  }, [format, selectedUsers, level, startTimestamp, endTimestamp]);

  const onLegendClick = useCallback(() => {
    window.open("https://docs.google.com/spreadsheets/d/1fz9TjoLnbjW5ZvKmn6dm3gYHmOuc92P-KHkoqi0IJt0/edit", "_blank");
  }, [])

  const intl = useIntl();

  return (
    <Flex flexDirection="column" m={2} alignItems="stretch">
      <Label
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
      >
        <FormattedMessage id="admin.reports.start_date" defaultMessage="Start date:" />
        <Input ml={2} my={1} type="date" value={startDate} onChange={onStartDateChange} />
        <Box p={2} sx={{ "&:hover": { opacity: 0.5 }, cursor: "pointer" }} onClick={onStartDateClear}>
          <FontAwesomeIcon icon={faTrash} />
        </Box>
      </Label>

      <Label
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
      >
        <FormattedMessage id="admin.reports.end_date" defaultMessage="End date:" />
        <Input ml={2} my={1} type="date" value={endDate} onChange={onEndDateChange} />
        <Box p={2} sx={{ "&:hover": { opacity: 0.5 }, cursor: "pointer" }} onClick={onEndDateClear}>
          <FontAwesomeIcon icon={faTrash} />
        </Box>
      </Label>

      <Label
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
      >
        <FormattedMessage id="admin.reports.level" defaultMessage="Level or quiz:" />
        <Select flex={1} ml={2} my={1} value={level ?? "all"} onChange={onChangeLevel}>
          <option value="all">
            {intl.formatMessage({ id: "admin.reports.level.all", defaultMessage: "All levels and quizes" })}
          </option>
          {Object.keys(LevelName).map((level) => (
            <option key={level} value={level}>
              {level}
            </option>
          ))}
        </Select>
      </Label>

      <Label
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
      >
        <FormattedMessage id="admin.reports.format" defaultMessage="Format:" />
        <Select flex={1} ml={2} my={1} value={format} onChange={onChangeFormat}>
          <option value="text/csv">CSV</option>
          <option value="application/json">JSON</option>
        </Select>
      </Label>


      <Button my={1} onClick={onHighScoreClick}>
        <FormattedMessage id="admin.reports.high_score" defaultMessage="Download high score report" />
      </Button>

      <Button my={1} onClick={onLatestScoreClick}>
        <FormattedMessage id="admin.reports.latest_score" defaultMessage="Download latest score report" />
      </Button>

      <Button my={1} onClick={onHistoryClick}>
        <FormattedMessage id="admin.reports.history" defaultMessage="Download score history" />
      </Button>

      {config.environment === "admin" && (
        <Button my={1} variant="outline" sx={{ cursor: "help" }} onClick={onLegendClick}>
          <FormattedMessage id="admin.reports.legend" defaultMessage="Score breakdown legend" />
        </Button>
      )}
    </Flex>
  );
}
