import { apiProductionUrl } from "../constants/servers";
import { LevelName, Score } from "../types/Score";
import { User, LoginResponse, UserRole, LevelOverrides } from "../types/User";
import { APIInvocation, JSONFetch, makeJsonFetch, defaultHttpErrorHandler } from "./api";
import { GlobalSettingsPerLevelValue, GlobalSettingsData } from "../types/GlobalSettings";

export type OptionWithID<K = number, N = string> = { id: K; name: N };

export type PaginationResult<T> = {
  items: ReadonlyArray<T>;
  totalCount: number;
  skip: number;
  take: number | null;
};

export interface AuthenticatedAPI {
  getUser: (userId: string) => APIInvocation<User>;
  deleteUser: (userId: string) => APIInvocation<{}>;
  getUsers: (search?: string, skip?: number, take?: number) => APIInvocation<PaginationResult<User>>;
  getSupervisedUsers: (userId: string, search?: string, skip?: number, take?: number) => APIInvocation<PaginationResult<User>>;
  patchUser: (userId: string, patch: Partial<User>) => APIInvocation<User>;
  patchRoles: (userIds: string[], patch: { add: UserRole[], remove: UserRole[] }) => APIInvocation<{}>;
  patchOverrides: (userIds: string[], patch: LevelOverrides) => APIInvocation<{}>;

  getTopScores: () => APIInvocation<{ scores: { nickname: string, score: number, friendCode?: string }[] }>;
  getLowScores: () => APIInvocation<GlobalSettingsPerLevelValue>;
  getAverageScores: (userId?: string) => APIInvocation<GlobalSettingsPerLevelValue>;
  getScoreHistory: (userId: string, level: LevelName, breakdown?: boolean) => APIInvocation<{ scores: number[]; timestamps: number[], breakdowns?: number[][] }>;
  getHighScore: (userId: string, level: LevelName) => APIInvocation<{ score?: Score }>;
  getFriendScores: (userId: string) => APIInvocation<{ user: User, score?: Score }[]>;

  getFriends: (userId: string, skip?: number, take?: number) => APIInvocation<PaginationResult<User>>;
  getIncomingFriendRequests: (userId: string, skip?: number, take?: number) => APIInvocation<PaginationResult<User>>;
  confirmIncomingFriendRequest: (userId: string, friendId: string) => APIInvocation<User>;
  sendFriendRequest: (userId: string, friendCode: string) => APIInvocation<{}>;
  deleteFriend: (userId: string, friendId: string) => APIInvocation<{}>;

  getSupervisors: (userId: string, skip?: number, take?: number) => APIInvocation<PaginationResult<User>>;
  addSupervisor: (userId: string, supervisorFriendCode: string) => APIInvocation<{}>;
  deleteSupervisor: (userId: string, supervisorId: string) => APIInvocation<{}>;

  getGlobalSettings: () => APIInvocation<GlobalSettingsData>;
  setGlobalSettings: (settings: GlobalSettingsData) => APIInvocation<GlobalSettingsData>;

  changePassword: (currentPassword: string, newPassword: string) => APIInvocation<{}>;

  revertPurchases: () => APIInvocation<{}>;
}

export interface AppAPI {
  loginLocal: (username: string, password: string, signup?: boolean, supervisorFriendCode?: string) => APIInvocation<LoginResponse>;
  getAuthenticatedApi: (login: LoginResponse) => AuthenticatedAPI;

  sendResetPasswordEmail: (email: string, languages: readonly string[]) => APIInvocation<{}>;
  validateResetPasswordToken: (token: string) => APIInvocation<{ username: string }>;
  resetPassword: (token: string, newPassword: string) => APIInvocation<{}>;
}

export class APIError extends Error {
  errorId: string;
  constructor(message: string, errorId: string) {
    super(message);
    this.errorId = errorId;
  }
}
const errorHandler = async (res: Response) => {
  let responseData;
  try {
    responseData = await res.json();
  } catch (ex) {}
  const error = responseData && responseData.errors && responseData.errors[0];
  if (!error) {
    return defaultHttpErrorHandler(res);
  }
  throw new APIError(error.message, error.id);
}

const noEmpty = (x: string | null | undefined) => (x == null || x === "") ? undefined : x;

export const makeApi = (options?: {
  apiUrl?: string;
  fetch?: JSONFetch; // for optional mocking
}): AppAPI => {
  const apiUrl = options?.apiUrl ?? apiProductionUrl;
  const fetch = options?.fetch ?? makeJsonFetch(apiUrl, undefined, errorHandler);
  return {
    loginLocal: (username, password, signup = false, supervisorFriendCode) =>
      fetch("POST", "/auth/local/login", { body: { username, password, signup, supervisorFriendCode } }),

    sendResetPasswordEmail: (email, languages) =>
      fetch("POST", "/auth/local/reset-password/send", { body: { email, languages } }),
    validateResetPasswordToken: (token) => fetch("GET", "/auth/local/reset-password", { query: { token } }),
    resetPassword: (token, newPassword) =>
      fetch("POST", "/auth/local/reset-password", { body: { token, newPassword } }),

    getAuthenticatedApi: ({ authToken }) => {
      const authFetch = options?.fetch ?? makeJsonFetch(apiUrl, { Authorization: `Bearer ${authToken}` }, errorHandler);
      return {
        getUser: (userId) => authFetch("GET", `/users/${userId}`),
        deleteUser: (userId) => authFetch("DELETE", `/users/${userId}`),
        getUsers: (search, skip, take) => authFetch("GET", `/users`, { query: { skip, take, search: noEmpty(search) } }),
        getSupervisedUsers: (userId, search, skip, take) =>
          authFetch("GET", `/users/${userId}/supervised`, { query: { skip, take, search: noEmpty(search) } }),
        patchUser: (userId, patch) => authFetch("PATCH", `/users/${userId}`, { body: patch }),
        patchRoles: (userIds, patch) => authFetch("PATCH", "/users/bulk/roles", { body: { users: userIds, ...patch } }),
        patchOverrides: (userIds, patch) =>
          authFetch("PATCH", "/users/bulk/overrides", { body: { users: userIds, patch } }),

        getTopScores: () => authFetch("GET", `/scores/top`),
        getLowScores: () => authFetch("GET", `/scores/low`),
        getAverageScores: (userId) => authFetch("GET", `/scores/${userId}/average`),
        getScoreHistory: (userId, level, breakdown) =>
          authFetch("GET", `/scores/${userId}/history/${level}`, { query: { breakdown } }),
        getHighScore: (userId, level) => authFetch("GET", `/scores/${userId}/high/${level}`),
        getFriendScores: (userId) => authFetch("GET", `/scores/${userId}/friends`),

        getFriends: (userId, skip, take) => authFetch("GET", `/users/${userId}/friends`, { query: { skip, take } }),
        sendFriendRequest: (userId, friendCode) =>
          authFetch("POST", `/users/${userId}/friends`, { body: { friendCode } }),
        deleteFriend: (userId, friendId) => authFetch("DELETE", `/users/${userId}/friends/${friendId}`),
        getIncomingFriendRequests: (userId, skip, take) =>
          authFetch("GET", `/users/${userId}/friends/incoming`, { query: { skip, take } }),
        confirmIncomingFriendRequest: (userId, friendId) =>
          authFetch("POST", `/users/${userId}/friends/incoming`, { body: { userId: friendId } }),

        getSupervisors: (userId, skip, take) =>
          authFetch("GET", `/users/${userId}/supervisors`, { query: { skip, take } }),
        addSupervisor: (userId, supervisorFriendCode) =>
          authFetch("POST", `/users/${userId}/supervisors`, { body: { supervisorFriendCode } }),
        deleteSupervisor: (userId, supervisorId) => authFetch("DELETE", `/users/${userId}/supervisors/${supervisorId}`),

        getGlobalSettings: () => authFetch("GET", "/settings"),
        setGlobalSettings: (settings) => authFetch("POST", "/settings", { body: settings }),

        changePassword: (currentPassword, newPassword) =>
          authFetch("POST", "/auth/local/change-password", { body: { currentPassword, newPassword } }),

        revertPurchases: () =>
          authFetch("POST", "/iap/revert", { body: {} }),
      };
    },
  };
};

const api = makeApi();
export default api;
