import React, { useEffect } from "react";
import { Box, Flex, Button, FlexProps, Heading } from "rebass";
import { Label, Input } from "@rebass/forms";
import { useCallback, useState } from "react";
import { useApiResponse } from "../hooks/useApiResponse";
import authStore, { useAuthStore } from "../stores/AuthStore";
import type { AuthStoreData } from "../stores/AuthStore";
import { FormattedMessage } from "react-intl";
import { ErrorBox } from "./ErrorBox";
import { TitleBarWrapper } from "./NavBarWrapper";
import { useHistory } from "react-router-dom";

const FlexForm: React.ComponentType<FlexProps> = (Flex as any).withComponent("form");

const confirmationPhrase = "DELETE";

export function DeleteAccountForm() {
  const [confirmation, setConfirmation] = useState<string>("");
  const [attempt, setAttempt] = useState<{ auth: AuthStoreData } | null>(null);

  const auth = useAuthStore();

  const requestState = useApiResponse(() => {
    if (!attempt) return null;
    const { auth } = attempt;
    if (!auth.userId) return null;
    return auth.authApi.deleteUser(auth.userId);
  }, [attempt]);

  const onChangeConfirmation = useCallback((evt) => setConfirmation(evt.target.value), []);
  const isConfirmationValid = confirmation === confirmationPhrase;

  const onSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      if (isConfirmationValid) {
        setAttempt(() => ({ auth }));
      }
    },
    [auth, isConfirmationValid],
  );

  const history = useHistory();

  useEffect(() => {
    if (requestState.hasData) {
      authStore.signOut();
      history.push("/");
    }
  }, [requestState.hasData, history]);

  return (
    <Flex flexDirection="column" alignItems="center">
      <FlexForm
        flexDirection="column"
        alignItems="stretch"
        maxWidth={500}
        sx={{
          width: "100%",
        }}
        px={2}
        py={3}
        onSubmit={onSubmit}
      >
        <Heading textAlign="center" color="primary" mb={3}>
          <FormattedMessage id="delete-account.subtitle" defaultMessage="Delete your account" />
        </Heading>

        <Box mb={3} sx={{ fontWeight: "bold" }}>
          <FormattedMessage
            id="delete-account.warning"
            defaultMessage="Warning! Deleting your account is permanent and the data is un-recoverable. Deleting your account will delete all scores and other data associated to your account."
          />
        </Box>

        <Label htmlFor="confirmation" sx={{ color: isConfirmationValid ? "error" : undefined }}>
          <FormattedMessage
            id="delete-account.confirmation.label"
            defaultMessage='Type "{confirmationPhrase}" to confirm deletion:'
            values={{ confirmationPhrase }}
          />
        </Label>
        <Input
          id="confirmation"
          type="text"
          placeholder={confirmationPhrase}
          value={confirmation}
          onChange={onChangeConfirmation}
          sx={{ borderColor: isConfirmationValid ? "error" : undefined }}
        />

        <Button mt={3} mb={2} variant="primary" type="submit" disabled={!isConfirmationValid || requestState.loading}>
          <FormattedMessage id="delete-account.button.submit" defaultMessage="Delete account" />
        </Button>
        <ErrorBox>{requestState.error}</ErrorBox>
      </FlexForm>
    </Flex>
  );
}

export const DeleteAccountPage = () => (
  <TitleBarWrapper title={<FormattedMessage id="delete-account.title" defaultMessage="Delete account" />}>
    <DeleteAccountForm />
  </TitleBarWrapper>
);
